<template>
	<div style="width:560; height:315;">
		<video
			v-if="isYoutube"
			class="video-js vjs-big-play-centered vjs-default-skin disablePictureInPicture"
			controls
			:autoplay = false
			fluid="true"
			preload="auto"
			ref="videoPlayer"
			:data-setup="options.data"
			style="border-radius: 25px;"
		>
		</video>
		<video v-if="!isYoutube" ref="videoPlayer" class="video-js vjs-big-play-centered"></video>
	</div>
</template>

<script>
	import videojs from 'video.js';
	import videojsyoutube from 'videojs-youtube';
	export default {
		name: "VideoPlayer",
		props: {
			options: {
				type: Object,
				default() {
					return {};
				}
			},
			isYoutube: {
				type: Boolean,
				default: false
			}
		},
		data() {
			return {
				player: null
			}
		},
		mounted() {
			this.player = videojs(this.$refs.videoPlayer, this.options, function onPlayerReady() {})
		},
		beforeDestroy() {
			if (this.player) {
				this.player.dispose()
			}
		}
	}
</script>